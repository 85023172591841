import { Button } from '@chakra-ui/button';
import { Box, Heading, Stack, Text } from '@chakra-ui/layout';
import useSearchStepSubmission from '@components/Analytics/events/SearchStepSubmission';
import useSearchSubmission from '@components/Analytics/events/SearchSubmission';
import { stepSubmission } from '@components/Analytics/events/eventContracts';
import { useRef, useState } from 'react';
import {
  GuidedSearchQuestion,
  GuidedSearchQuestionOption,
  GuidedSearchQuestionType,
} from 'types/GuidedSearch';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

import Stepper from '../Stepper';
import GuidedSearchFormInput from './GuidedSearchFormInput';
import GuidedSearchFormMultiple from './GuidedSearchFormMultiple';
import GuidedSearchFormResult from './GuidedSearchFormResult';
import GuidedSearchFormSelect from './GuidedSearchFormSelect';

interface GuidedSearchFormProps {
  modalId: string;
  guidedSearchId: string;
  questions: Array<GuidedSearchQuestion>;
}

const GuidedSearchForm = ({
  modalId,
  guidedSearchId,
  questions,
}: GuidedSearchFormProps): JSX.Element => {
  const guidedSearchRef = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [emptyValuesError, setEmptyValuesError] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [searchInstanceId, setSearchInstanceId] = useState(uuidv4());
  const [values, setValues] = useState<{
    [key: number]: Array<GuidedSearchQuestionOption>;
  }>({});
  const [stepSubmissions, setStepSubmissions] = useState<stepSubmission[]>([]);

  const searchStepSubmission = useSearchStepSubmission();
  const searchSubmission = useSearchSubmission();
  if (!questions || questions?.length === 0) {
    return <></>;
  }

  const handleChange = (value: Array<GuidedSearchQuestionOption>) => {
    setValues((current) => ({ ...current, [currentStep]: value }));
  };

  const handleReset = () => {
    setShowResult(false);
    setEmptyValuesError(false);
    setCurrentStep(0);
    setValues({});
    scrollToForm();
    setSearchInstanceId(uuidv4());
  };

  const handleSearchSubmission = () => {
    searchSubmission({
      search_template_id: guidedSearchId,
      search_instance_id: searchInstanceId,
      step_submissions: stepSubmissions,
    });
  };

  const renderQuestion = () => {
    const question = questions[currentStep];
    if (!question) return null;

    switch (question.type) {
      case GuidedSearchQuestionType.text:
        return (
          <GuidedSearchFormInput
            value={values[currentStep]}
            onChange={handleChange}
            key={currentStep}
          />
        );
      case GuidedSearchQuestionType.multiple:
        return (
          <GuidedSearchFormMultiple
            question={question}
            key={currentStep}
            onChange={handleChange}
            value={values[currentStep]}
          />
        );
      case GuidedSearchQuestionType.select:
        return (
          <GuidedSearchFormSelect
            onChange={handleChange}
            question={question}
            key={currentStep}
            value={values[currentStep]}
          />
        );
    }
  };

  const scrollToForm = () => {
    if (!guidedSearchRef.current) {
      return;
    }
    guidedSearchRef.current.scrollIntoView();
  };

  const handleNextStep = () => {
    handleSearchEvents();
    if (currentStep >= questions.length - 1) {
      if (Object.keys(values).length > 0) {
        setShowResult(true);
        setEmptyValuesError(false);
      } else {
        setEmptyValuesError(true);
      }
      return;
    }
    scrollToForm();
    setCurrentStep((current) => current + 1);
  };

  const handleSearchEvents = () => {
    const promptIndex = 1; //There's only one question per step
    const stepInstanceId = uuidv4();
    const promptId = uuidv5(
      `${questions[currentStep].questionText}-${promptIndex}`,
      guidedSearchId
    );
    const responseType =
      questions[currentStep].type === GuidedSearchQuestionType.text
        ? 'freeText'
        : 'labelText';

    const responseValue =
      values[currentStep]?.length > 0
        ? values[currentStep].map((u) => u[responseType]).join(', ')
        : '';

    // If question type is 'text', generate UUID with 'questionText', and use 'guidedSearchId' for the namespace
    // If question type is 'multiple' or 'option', combine `questionText` and `labelText`, and use 'guidedSearchId' for the namespace
    // If the question or answers change, a new UUID will get generated
    const stepId = uuidv5(
      questions[currentStep].type === GuidedSearchQuestionType.text
        ? questions[currentStep].questionText
        : `${questions[currentStep].questionText}-${questions[
            currentStep
          ].options
            ?.map((u) => u.labelText)
            .join(' ')}`,
      guidedSearchId
    );

    searchStepSubmission({
      search_template_id: guidedSearchId,
      search_instance_id: searchInstanceId,
      step_id: stepId,
      step_instance_id: stepInstanceId,
      step_index: currentStep + 1,
      step_content: [
        {
          prompt_id: promptId,
          prompt_type: questions[currentStep].type,
          prompt_instance_id: uuidv4(),
          prompt_index: promptIndex,
          prompt_value: questions[currentStep].questionText,
          response_array: [
            {
              response_value: responseValue,
              response_id: uuidv4(),
            },
          ],
        },
      ],
    });

    const stepSubmission = stepSubmissions;

    stepSubmission.push({
      step_id: stepId,
      step_instance_id: stepInstanceId,
      step_index: currentStep + 1,
    });

    setStepSubmissions(stepSubmission);
  };

  return (
    <>
      <GuidedSearchFormResult
        modalId={modalId}
        questions={questions}
        values={values}
        handleReset={handleReset}
        handleSearchSubmission={handleSearchSubmission}
        isOpen={showResult}
        onClose={() => setShowResult(false)}
      />
      <Box py={{ lg: 10, base: 4 }} width="full" ref={guidedSearchRef}>
        <Stepper
          steps={questions.map((question, index) => ({
            label: `STEP ${index + 1}`,
            description: question.stepLabelText,
          }))}
          currentStepIndex={currentStep}
        />
        <>
          <Box maxW="4xl" mx="auto" pt="8">
            <Heading
              fontWeight="bold"
              fontSize="2xl"
              textAlign="center"
              color="primary.600"
            >
              {questions[currentStep].questionText}
            </Heading>
            {questions[currentStep].descriptionText && (
              <Text fontFamily="Georgia" textAlign="center" color="primary.600">
                {questions[currentStep].descriptionText}
              </Text>
            )}
            <Box paddingY="8">{renderQuestion()}</Box>
            <Box
              display="flex"
              flexDirection={{ base: 'column', lg: 'row' }}
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              {currentStep > 0 && (
                <Button
                  variant="outline"
                  colorScheme="primary"
                  maxW="52"
                  py="8"
                  width="100%"
                  mb={{ base: 2, lg: 0 }}
                  onClick={() => {
                    setEmptyValuesError(false);
                    setCurrentStep((current) => current - 1);
                    scrollToForm();
                  }}
                >
                  Previous
                </Button>
              )}
              <Stack
                direction={{
                  base: 'column-reverse',
                  lg: 'row',
                }}
                spacing={{ lg: 8, base: 4 }}
                alignItems="center"
                width="100%"
                justifyContent="flex-end"
              >
                <Button
                  py="8"
                  maxW="52"
                  width="100%"
                  onClick={() => handleNextStep()}
                  colorScheme="accent"
                >
                  Next
                </Button>
              </Stack>
            </Box>
            {emptyValuesError && (
              <Text color="red.500" textAlign="center" my="2">
                You must provide an answer in at least one of the questions.
              </Text>
            )}
          </Box>
        </>
      </Box>
    </>
  );
};

export default GuidedSearchForm;
