import { Box, Grid, Text } from '@chakra-ui/layout';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import {
  GuidedSearchQuestion,
  GuidedSearchQuestionOption,
} from 'types/GuidedSearch';

interface Props {
  question: GuidedSearchQuestion;
  onChange: (value: GuidedSearchQuestionOption[]) => void;
  value?: GuidedSearchQuestionOption[];
}

const GuidedSearchFormSelect: React.FC<Props> = ({
  question,
  value = [],
  onChange,
}) => {
  const handleRadioChange = (value: string) => {
    const questionOption = question.options?.find((n) => n.labelText === value);
    if (questionOption && onChange) onChange([questionOption]);
  };

  const renderOption = (option: GuidedSearchQuestionOption, index: number) => {
    const isSelected =
      value.findIndex((n) => n.labelText === option.labelText) >= 0;

    return (
      <Box display="flex" width="full" key={index}>
        <Box
          as="label"
          htmlFor={option.labelText}
          rounded="md"
          width="full"
          cursor="pointer"
          alignItems="center"
          justifyContent="center"
          py="5"
          shadow="lg"
          bg={isSelected ? 'accent.100' : 'white'}
          transitionDuration="250ms"
        >
          <Text textAlign="center">{option.labelText}</Text>
        </Box>
        <Radio
          name="option"
          value={option.labelText}
          id={option.labelText}
          srOnly
          type="radio"
        />
      </Box>
    );
  };

  return (
    <RadioGroup value={value[0]?.labelText || ''} onChange={handleRadioChange}>
      <Grid
        gridTemplateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }}
        gap="4"
      >
        {question.options?.map(renderOption)}
      </Grid>
    </RadioGroup>
  );
};

export default GuidedSearchFormSelect;
