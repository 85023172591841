import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Box } from '@chakra-ui/layout';
import { MdSearch } from 'react-icons/md';
import { GuidedSearchQuestionOption } from 'types/GuidedSearch';

interface Props {
  onChange: (value: GuidedSearchQuestionOption[]) => void;
  value?: GuidedSearchQuestionOption[];
}

const GuidedSearchFormInput: React.FC<Props> = ({ value = [], onChange }) => {
  return (
    <InputGroup bg="white" size="lg" rounded="md">
      <InputLeftElement pointerEvents="none">
        <Box as={MdSearch} size="22" color="primary.500" />
      </InputLeftElement>
      <Input
        placeholder="New York, NY"
        border="none"
        fontWeight="600"
        color="primary.600"
        value={value[0]?.freeText || ''}
        _placeholder={{ color: 'primary.100' }}
        onChange={(event) =>
          onChange([{ freeText: event.currentTarget.value }])
        }
      />
    </InputGroup>
  );
};

export default GuidedSearchFormInput;
