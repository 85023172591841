import { Checkbox, CheckboxGroup } from '@chakra-ui/checkbox';
import { Box, Flex, Grid, Text } from '@chakra-ui/layout';
import {
  GuidedSearchQuestion,
  GuidedSearchQuestionOption,
} from 'types/GuidedSearch';

interface Props {
  question: GuidedSearchQuestion;
  onChange: (value: GuidedSearchQuestionOption[]) => void;
  value?: GuidedSearchQuestionOption[];
}

const GuidedSearchFormMultiple: React.FC<Props> = ({
  question,
  value = [],
  onChange,
}) => {
  const handleCheckboxChange = (value: string[]) => {
    const nextValue: Array<GuidedSearchQuestionOption> = [];

    value.forEach((labelText) => {
      const option = question.options?.find((n) => n.labelText === labelText);
      if (option) nextValue.push(option);
    });

    if (onChange) onChange(nextValue);
  };

  const renderOption = (option: GuidedSearchQuestionOption, index: number) => {
    const isSelected =
      value.findIndex((n) => n.labelText === option.labelText) >= 0;

    return (
      <Box display="flex" width="full" key={index}>
        <Flex
          as="label"
          htmlFor={option.labelText}
          rounded="md"
          width="full"
          cursor="pointer"
          alignItems="center"
          justifyContent="center"
          py="5"
          shadow="lg"
          bg={isSelected ? 'accent.100' : 'white'}
          transitionDuration="250ms"
        >
          <Text>{option.labelText}</Text>
        </Flex>
        <Checkbox
          name="option"
          value={option.labelText}
          id={option.labelText}
          srOnly
          type="checkbox"
        />
      </Box>
    );
  };

  return (
    <CheckboxGroup
      onChange={handleCheckboxChange}
      value={value.map((n) => n.labelText ?? '')}
    >
      <Grid
        gridTemplateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }}
        gap="4"
      >
        {question.options?.map(renderOption)}
      </Grid>
    </CheckboxGroup>
  );
};

export default GuidedSearchFormMultiple;
