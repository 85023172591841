import { Box } from '@chakra-ui/layout';
import { stringSanitizer } from '@utils/strings';
import dynamic from 'next/dynamic';
import {
  DataSourceField,
  GuidedSearchQuestion,
  ManualQuestions,
} from '~/types/GuidedSearch';
import { Metadata } from '~/types/Magnolia';
import GuidedSearchForm from './GuidedSearchForm';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
interface GuidedSearchProps {
  inquiryId: string;
  dataSource: {
    field: DataSourceField;
    questions: ManualQuestions | string;
  };
  metadata: Metadata;
}
const parseJson = (data) => {
  try {
    const dataJsonPrepare = stringSanitizer(data);
    return JSON.parse(dataJsonPrepare);
  } catch {
    console.error(
      'Invalid json: please check your data is a properly formatted JSON',
      data?.toString()
    );
  }
};

const getOptions = (optionField) => {
  if (optionField.field === 'text') return '';
  if (optionField.field === 'multiple')
    return parseJson(optionField.multipleOptions)?.options;
  return parseJson(optionField.selectOptions)?.options;
};

const getQuestions = (field, questions): Array<GuidedSearchQuestion> => {
  if (field === 'manually' && questions['@nodes'].length > 0) {
    return questions['@nodes'].map((question) => {
      return {
        stepLabelText: questions[question].stepLabelText,
        questionText: questions[question].questionText,
        questionKey: questions[question].questionKey,
        descriptionText: questions[question].descriptionText,
        type: questions[question].optionType.field,
        options: getOptions(questions[question].optionType),
      };
    });
  } else {
    return parseJson(questions);
  }
};

const GuidedSearch = (props: GuidedSearchProps): JSX.Element => {
  const { inquiryId, dataSource } = props;
  const { field, questions } = dataSource;
  const parsedQuestions = getQuestions(field, questions);
  if (!parsedQuestions || parsedQuestions.length === 0) {
    return <></>;
  }
  return (
    <Container>
      <Box
        bg="secondary.50"
        display="flex"
        justifyContent="center"
        position="relative"
        alignItems="center"
        px="10"
        rounded="xl"
      >
        <GuidedSearchForm
          modalId={inquiryId}
          guidedSearchId={props.metadata['@id']}
          questions={parsedQuestions}
        />
      </Box>
    </Container>
  );
};

export default GuidedSearch;
