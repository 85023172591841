import { Stack } from '@chakra-ui/layout';
import { Hide, Show } from '@chakra-ui/media-query';
import StepperItem from './StepperItem';

type StepItem = {
  label: string;
  description: string;
};

interface Props {
  steps: Array<StepItem>;
  currentStepIndex: number;
}

const Stepper: React.FC<Props> = ({ steps, currentStepIndex }) => {
  const currentStep = steps[currentStepIndex];

  return (
    <>
      <Show above="lg">
        <Stack direction={{ base: 'column', lg: 'row' }} spacing="4">
          {steps.map((step, index) => (
            <StepperItem
              title={step.label}
              description={step.description}
              key={index}
              isActive={index === currentStepIndex}
              isCompleted={index <= currentStepIndex}
            />
          ))}
        </Stack>
      </Show>
      <Hide above="lg">
        <StepperItem
          title={currentStep.label}
          description={currentStep.description}
          isActive
        />
      </Hide>
    </>
  );
};

export default Stepper;
