export enum GuidedSearchQuestionType {
  select = 'select',
  multiple = 'multiple',
  text = 'text',
}

export enum DataSourceField {
  json = 'json',
  manually = 'manually',
}

export type GuidedSearchQuestionOption = {
  labelText?: string;
  freeText?: string;
  scores?: { [key: string]: number };
};

export type GuidedSearchQuestion = {
  stepLabelText: string;
  questionText: string;
  questionKey: string;
  descriptionText?: string;
  type: GuidedSearchQuestionType;
  options?: Array<GuidedSearchQuestionOption>;
};

export type GuidedSearchResult = {
  [key: number]: Array<GuidedSearchQuestionOption>;
};

export type ManualQuestions = {
  '@name': string;
  '@path': string;
  '@id': string;
  '@nodeType': string;
  'mgnl:lastModified': string;
  'mgnl:created': string;
  '@nodes': string[] | null;
} & { [key: string]: Question };

interface Question {
  '@name'?: string;
  '@path'?: string;
  '@id'?: string;
  '@nodeType'?: string;
  questionText?: string;
  stepLabelText?: string;
  descriptionText?: string;
  questionKey?: string;
  optionType?: {
    '@name'?: string;
    '@path'?: string;
    '@id'?: string;
    '@nodeType'?: string;
    field?: string;
    selectOptions?: string;
    '@nodes'?: null[] | null;
  };
  '@nodes'?: string[] | null;
}
