import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Box, Heading, Stack, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/table';
import { useToast } from '@chakra-ui/toast';
import { StringToIconKeys, STRING_TO_ICON_CLASS } from '@components/RenderIcon';
import useSearch from '@hooks/useSearch';
import { icons } from '@utils/careTypeIcons';
import { capitalizeFullSentence } from '@utils/strings';
import { useGuidedSearchData } from 'contexts/GuidedSearchContext';
import { useState } from 'react';
import { GuidedSearchQuestion, GuidedSearchResult } from 'types/GuidedSearch';
import { getGuidedSearchResult } from 'utils/guided-search';
import { useModal } from '~/contexts/ModalContext';
import useTranslation from '~/hooks/use-translation';

// import { useCurrentUser } from '~/hooks/session';
const getIcon = (iconName?: StringToIconKeys) => {
  if (!iconName) return '';
  return (
    <Icon color="blue.500" as={STRING_TO_ICON_CLASS[iconName]} boxSize={100} />
  );
};

interface Props {
  modalId: string;
  values: GuidedSearchResult;
  handleReset: () => void;
  handleSearchSubmission: () => void;
  onClose: () => void;
  questions: Array<GuidedSearchQuestion>;
  isOpen: boolean;
  displayPersonalizedCare?: boolean;
  displaySearchResults?: boolean;
  displayStartOver?: boolean;
}

const GuidedSearchFormResult: React.FC<Props> = ({
  modalId,
  values,
  handleReset,
  handleSearchSubmission,
  onClose,
  questions,
  isOpen,
  displayPersonalizedCare = true,
  displaySearchResults = true,
  displayStartOver = true,
}) => {
  const { buildSearchUrl } = useSearch();
  const { show: showInquiryForm } = useModal(modalId);
  const { t } = useTranslation();
  const {
    setGuidedSearchQuestions,
    setGuidedSearchValues,
    setGuidedSearchMatchingCareType,
  } = useGuidedSearchData();

  const toast = useToast();
  // const { data: user } = useCurrentUser();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const result = getGuidedSearchResult(values, questions);

  const handleCreateGuidedSearch = () => {
    // createGuidedSearch(result, {
    //   onSuccess: () => {
    //     toast({
    //       description: 'A profile for this search has been created.',
    //       position: 'top-right',
    //       isClosable: true,
    //       duration: 6000,
    //       status: 'success',
    //     });
    //   },
    // });
    setShowAuthModal(false);
  };

  const careType = capitalizeFullSentence(
    result.matchingCareType?.replaceAll('-', ' ')
  );
  const careTypeURL = careType?.replaceAll(' ', '+');

  const locale = () => {
    const keys = questions.map((question) => question.questionKey);
    const index = keys.indexOf('location_keyword');
    return values[index]?.[0]?.freeText;
  };

  const saveResult = () => {
    setGuidedSearchQuestions(questions);
    setGuidedSearchValues(values);
    setGuidedSearchMatchingCareType(result.matchingCareType);
    onClose();
  };

  const goToSearchPage = () => {
    const searchPageUrl = buildSearchUrl({
      careType: careTypeURL,
      keyword: locale(),
    });

    location.assign(searchPageUrl);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={saveResult} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" py="10">
            <Heading fontSize="2xl" fontWeight="800">
              Your recommended Care Type
            </Heading>
            <Text
              fontSize="lg"
              fontWeight="400"
              fontFamily="Georgia"
              textAlign="center"
              color="gray.600"
            >
              Based on your search requirements...
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box bg="secondary.50" p="4" rounded="lg">
              <Heading
                textAlign="center"
                fontSize="xl"
                pb="4"
                textTransform="capitalize"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {getIcon(icons[result.matchingCareType])}
                {careType}
              </Heading>
              <Box
                bg="white"
                border="1px"
                rounded="md"
                p="2"
                borderColor="gray.200"
              >
                <TableContainer whiteSpace="normal">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Question</Th>
                        <Th>Answer</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {questions.map((question, index) => (
                        <Tr
                          key={index}
                          bg={index % 2 === 0 ? 'secondary.50' : undefined}
                        >
                          <Td>{question.stepLabelText}</Td>
                          <Td>
                            {values[index]
                              ?.map(
                                (value) => value.labelText || value.freeText
                              )
                              .join(', ')}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box py="8">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                justifyContent="center"
              >
                {displayPersonalizedCare && (
                  <Button
                    colorScheme="accent"
                    onClick={() => {
                      saveResult();
                      onClose();
                      showInquiryForm();
                    }}
                  >
                    Get Personalized Care
                  </Button>
                )}
                {displaySearchResults && (
                  <Button
                    variant="outline"
                    onClick={() => {
                      saveResult();
                      goToSearchPage();
                      handleSearchSubmission();
                    }}
                  >
                    View Search Results
                  </Button>
                )}
              </Stack>
              {displayStartOver && (
                <Box textAlign="center" pt="4">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      saveResult();
                      handleReset();
                    }}
                  >
                    Start Over
                  </Button>
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onSuccess={handleCreateGuidedSearch}
      /> */}
    </>
  );
};

export default GuidedSearchFormResult;
