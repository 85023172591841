import { Box, Stack, Text } from '@chakra-ui/layout';

interface Props {
  title: string;
  description: string;
  isActive?: boolean;
  isCompleted?: boolean;
}

export const StepperItem: React.FC<Props> = ({
  title,
  description,
  isActive = false,
  isCompleted = false,
}) => {
  return (
    <Box
      flex="1"
      py={{ base: '2', lg: '3' }}
      ps={{ base: '3', lg: '0' }}
      borderTopWidth={{ base: '0', lg: '4px' }}
      borderLeftWidth={{ base: '4px', lg: '0' }}
      borderColor={isActive ? 'accent.500' : 'primary.600'}
    >
      <Stack spacing="0.5">
        <Text
          fontSize="sm"
          color={isActive ? 'accent.500' : 'primary.600'}
          fontWeight="bold"
        >
          {title}
        </Text>
        <Text color={isActive ? 'accent.500' : 'primary.600'} fontSize="sm">
          {description}
        </Text>
      </Stack>
    </Box>
  );
};

export default StepperItem;
