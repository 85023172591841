import {
  GuidedSearchQuestion,
  GuidedSearchQuestionOption,
  GuidedSearchResult,
} from 'types/GuidedSearch';

export const getGuidedSearchTotalScores = (
  questions: Array<GuidedSearchQuestion>,
  values: { [key: number]: Array<GuidedSearchQuestionOption> }
): { [key: string]: number } => {
  const totalScores: { [key: string]: number } = {};

  questions.forEach((_, index) => {
    const questionValues = values[index] || [];
    questionValues.forEach((value) => {
      if (value.scores) {
        const keys = Object.keys(value.scores);
        keys.forEach((key) => {
          totalScores[key] = (totalScores[key] || 0) + 1;
        });
      }
    });
  });

  return totalScores;
};

export const getMaxScoreCareType = (careTypeScores: {
  [key: string]: number;
}): string => {
  const careTypeKeys = Object.keys(careTypeScores);
  let maxScoreCareTypeKey = careTypeKeys[0];
  careTypeKeys.forEach((currentCareTypeKey) => {
    if (
      careTypeScores[currentCareTypeKey] > careTypeScores[maxScoreCareTypeKey]
    ) {
      maxScoreCareTypeKey = currentCareTypeKey;
    }
  });

  return maxScoreCareTypeKey;
};

export const getRecommendedCareType = (
  values: GuidedSearchResult,
  guidedSearchQuestions
) => {
  const totalScores = getGuidedSearchTotalScores(guidedSearchQuestions, values);
  const maxScoreCareType = getMaxScoreCareType(totalScores);
  return maxScoreCareType;
};

export const getGuidedSearchResult = (
  values: GuidedSearchResult,
  questions: Array<GuidedSearchQuestion>
) => {
  return {
    matchingCareType: getRecommendedCareType(values, questions),
    searchSubmission: questions.map((question, index) => ({
      questionKey: question.questionKey,
      questionType: question.type,
      answer:
        values[index]?.map((answer) => answer.labelText || answer.freeText) ||
        [],
    })),
  };
};
